import React from 'react';
import ORBNavBar from '../../../components/common/ORBNavBar';
import ORBApi from '../../../services/ORBApi';
import DateMonthPicker from '../../../components/common/DateMonthPicker';
import { Button, Grid, TextField } from '@mui/material';
import SuggestContributors from '../../../components/common/SuggestContributors';
import Loading from '../../../components/common/Loading';
import * as moment from 'moment';
import { toast } from 'react-toastify';

const AdminReports = ()=> {

    const [filters, setFilters] = React.useState({start_date: moment().subtract("month", 12).toDate(), end_date: new Date(), isrc: "", contributor: ""});
    const [processing, setProcessing] = React.useState(false);
    const [errors, setErrors] = React.useState({isrc: false, contributor: false})

    const validateParams = () => {
        let err = {isrc: false};
        let triggered = false;
        if (!filters.isrc) {
            triggered = true;
            err.isrc = true;
        }

        setErrors(err);

        return !triggered;
    }
    const exportReport = ()=> {
        if (!validateParams()) return;
        setProcessing(true);
        //format the date correctly
        let startDate = moment(filters.start_date).format("YYYY-MM");
        let endDate = moment(filters.end_date).format("YYYY-MM");
        ORBApi.getAdminTrackReport(startDate, endDate, filters.isrc, filters.contributor)
        .then((data) => {
            const file = new Blob([data], { type: `application/excel` });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            link.download = `ORB-not-found-works.xls`;
            link.click();
            toast.success("Relatório exportado com sucesso.")
        })
        .catch((ex) => {
            console.log(ex);
            toast.error("Falha ao baixar o relatório.")
        })
        .finally(()=> {
            setProcessing(false);
        })
    }

    return (
        <div id="reports-page">
            {/*Website navigation Bar  */}
            <ORBNavBar page_title="Admin > Levantamento Faixa" selectedOption="drop_admin" />
            <Grid container style={styles.grid_container}>
                <Grid item md={3} xs={12} style={styles.grid_item}>
                    <DateMonthPicker label="De" format={"MM/yyyy"} view={["month", "year"]} required
                        value={filters.start_date} onChange={(month)=> {setFilters({...filters, start_date: month})}}/>
                </Grid>
                <Grid item md={3} xs={12} style={styles.grid_item}>
                    <DateMonthPicker label="Até" format={"MM/yyyy"} view={["month", "year"]} required
                        value={filters.end_date} onChange={(month)=> {setFilters({...filters, end_date: month})}}/>
                </Grid>
                <Grid item md={3} xs={12}></Grid>
                <Grid item md={3} xs={12}></Grid>
                <Grid item md={3} xs={12} style={styles.grid_item_with_padding}>
                    <TextField label="ISRC" variant="outlined" required style={{width:'100%'}} error={errors.isrc}
                    value={filters.isrc} onChange={(e) => {setFilters({...filters, isrc:e.target.value})}}/>
                </Grid>
                <Grid item md={3} xs={12} style={styles.grid_item_with_padding}>
                    <SuggestContributors margin={false} useToFilter={true}
                        onChange={(contributor) => { setFilters({...filters, contributor: contributor.id}) }}/>
                </Grid>
                <Grid item md={3} xs={12}></Grid>
                <Grid item md={3} xs={12}></Grid>
                <Grid item md={3} xs={12}></Grid>
                <Grid item md={3} xs={12} style={styles.grid_item_button}>
                    {processing ?
                    <Loading />
                    : <Button variant="contained" style={{backgroundColor: "#f50057"}} size="large"
                        onClick={exportReport}>Exportar</Button>
                    }
                </Grid>
            </Grid>
        </div>
    )
};

const styles = {
    grid_container: {
        paddingLeft: 20,
        paddingRight: 10
    },
    grid_item: {
        paddingRight: 10,
        alignContent: 'center'
    },
    grid_item_with_padding: {
        paddingRight: 10,
        alignContent: 'center',
        paddingTop:5
    },
    grid_item_button: {
        paddingTop: 10,
        textAlign: 'right',
        paddingRight: 10
    }
}

export default AdminReports;