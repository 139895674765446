import React from 'react';
import { Button, FormControl, Grid, InputLabel, Select, TextField } from '@material-ui/core';
import CpfCnpj from '../../../../components/common/CpfCnpj';
import SuggestContributors from '../../../../components/common/SuggestContributors';
import ClientsDropDown from '../../../../components/common/ClientsDropdown';
import ORBApi from '../../../../services/ORBApi';
import Loading from '../../../../components/common/Loading';
import { toast } from 'react-toastify';

const NewUserPanel = ({onCreate}) => {

    const [isRunning, setIsRunning] = React.useState(false);
    const [newUserInfo, setNewUserInfo] = React.useState(
                {name:'', email:'', document:'', type:'ARTIST', contributor_name: '', contributor_id: '', client_id: ''});
    const [formErrors, setFormErrors] = React.useState(
        {
            name: false,
            email: false,
            document: false,
            type: false,
            contributor: false,
            client_id: false
        }
    )

    const validateForm = () => {
        let triggered = false;
        let newErrors = { name: false, email: false, document: false, type: false, contributor: false, client_id: false }

        //Name
        if (newUserInfo.name.length< 2){
            newErrors.name = true;
            triggered = true;
        }
        //Basic email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!newUserInfo.email || !emailPattern.test(newUserInfo.email)) {
            newErrors.email = true;
            triggered = true;
        }
        //Document
        let docSize = newUserInfo.document.replace(/\D/g, '').length
        if (!newUserInfo.document || (docSize !== 11 && docSize !== 14)) {
            newErrors.document = true;
            triggered = true;
        }
        // User Type
        if (newUserInfo.type === "ARTIST"){
            // must add the contributor
            if (!newUserInfo.contributor_id) {
                newErrors.contributor = true;
                triggered = true;
            }
        } else if (newUserInfo.type === "CLIENT"){
            // must add client information
            if (!newUserInfo.client_id) {
                newErrors.client = true;
                triggered = true;
            }
        }

        setFormErrors(newErrors);
        return !triggered
    }

    const createUser = () => {
        if (!validateForm())
            return;

        setIsRunning(true);
        ORBApi.createUser(newUserInfo.name, newUserInfo.email, newUserInfo.document, newUserInfo.type, newUserInfo.contributor_id, newUserInfo.client_id) 
        .then((user)=> {
            if (onCreate)
                onCreate(user);
        })
        .catch((ex) => {
            toast.error(`Houve um problema na criação do usuário.${ex.message}`);
            console.log(ex);
        })
        .finally(() => {
            setIsRunning(false);
        })
    }
    return (
        <>
        <h4 className='title'>Novo usuário</h4>
        <hr/>
        <Grid container spacing={2} alignItems="center">
            <Grid item md={4} xs={12}>
                <TextField style={{ width: '100%' }} variant="outlined" required label="Nome" value={newUserInfo.name}
                    error={formErrors.name} onChange={(e) => {
                                                setNewUserInfo({...newUserInfo, name: e.target.value});
                                                setFormErrors({...formErrors, name: false});
                                                }}/>
            </Grid>
            <Grid item md={4} xs={12}>
                <TextField style={{ width: '100%' }} variant="outlined" required label="Email" value={newUserInfo.email}
                    error={formErrors.email} onChange={(e) => {
                                                setNewUserInfo({...newUserInfo, email: e.target.value});
                                                setFormErrors({...formErrors, email: false});
                                                }}/>
            </Grid>
            <Grid item md={4} xs={12}>
                <CpfCnpj label="Documento" required value={newUserInfo.document}
                    error={formErrors.document} onChange={(e) => {
                                                setNewUserInfo({...newUserInfo, document: e.target.value});
                                                setFormErrors({...formErrors, document: false});
                                                }}/>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl style={{ width: '100%' }} required error={formErrors.type}>
                    <InputLabel className="select-label">Perfil</InputLabel>
                    <Select native variant="outlined"
                        onChange={(e) => {
                                        setNewUserInfo({...newUserInfo, type: e.target.value});
                                        setFormErrors({...formErrors, type: false});
                                        }}>
                    <option value={"ARTIST"}>Titular</option>
                    <option value={"CLIENT"}>Cliente(Selo/Editora)</option>
                    <option value={"ORBTEAM"}>ORB Team</option>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                {newUserInfo.type === "ARTIST" ? 
                    <SuggestContributors margin={false} useToFilter={true} error={formErrors.contributor}
                        onChange={(contributor) => {
                                            setNewUserInfo({...newUserInfo, contributor_name:contributor.name, contributor_id: contributor.id});
                                            setFormErrors({...formErrors, contributor: false});
                                        }}/>
                : newUserInfo.type === "CLIENT" ?
                    <ClientsDropDown margin="none" label="Cliente" required error={formErrors.client}
                    onChange={(client_id) =>{
                                        setNewUserInfo({...newUserInfo, client_id: client_id});
                                            setFormErrors({...formErrors, client: false});
                                    }}/>
                : null
                }
            </Grid>
            <Grid item md={12} xs={12} style={{textAlign: 'right', marginTop:'135px'}}>
                {isRunning ? 
                <Loading size="25px"/>:
                <Button variant="contained" color="secondary" size="large"
                    onClick={createUser}>Criar</Button>
                }
            </Grid>

        </Grid>
        </>
    )
}

export default NewUserPanel;