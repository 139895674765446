import React, { useEffect, useState } from 'react';
import ORBApi from '../../services/ORBApi';
import { Select, InputLabel, FormControl } from '@material-ui/core';

export default function ClientsDropdown({
  value,
  onChange,
  className,
  hideEmpty,
  margin,
  label,
  error
}) {
  const [clients, setClients] = useState([]);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    //Load the clients when the component mount
    ORBApi.loadClientsDropdown().then((response) => {
      setClients(response.clients);
    });
  }, []);

  const onChangeHandler = (e) => {
    setSelected(e.target.value);
    onChange(e.target.selectedOptions[0].getAttribute('client_id'));
  };

  return (
    <FormControl
      fullWidth
      margin={margin}
      variant="outlined"
      className={className}
      error={error}
    >
      <InputLabel shrink={selected > 0}>{label}</InputLabel>
      <Select
        native
        label={label}
        onChange={onChangeHandler}
        value={selected}
        notched={selected > 0}
      >
        {!hideEmpty ? <option value="" /> : null}
        {clients.map((client) => {
          return (
            <option key={client.id} value={client.id} client_id={client.id}>
              {client.name}
            </option>
          );
        })}
        ;
      </Select>
    </FormControl>
  );
}
