import React, { useEffect } from 'react';
import ORBNavBar from '../../../components/common/ORBNavBar';
import ORBApi from '../../../services/ORBApi';
import UsersTable from './components/UsersTable';
import Pagination from '@material-ui/lab/Pagination';
import PlusIcon from '../../../icons/PlusIcon';
import FilterIcon from '../../../icons/FilterIcon';
import { Modal } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import NewUserPanel from './components/NewUserPanel';
import { toast } from 'react-toastify';
import Loading from '../../../components/common/Loading';
import DebouncedTextField from '../../../components/common/DebouncedTextField';

const Users = () => {

    const [userList, setUserList] = React.useState({current_page:1, pages:1, users:[]});
    const [searchFilter, setSearchFilter] = React.useState("");
    const [showCreateUserPanel, setShowCreateUserPanel] = React.useState(false);
    const [showFilters, setShowFilters] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        listUsers(1);
    }, []);

    const listUsers = (page, search_filter) => {
        setIsLoading(true);
        setSearchFilter(search_filter);
        ORBApi.listUsers(page, search_filter)
        .then((data) => {
           setUserList(data); 
        })
        .catch((ex) => {
            toast.error("Falha ao carregar usuários.")
        })
        .finally(() => {
            setIsLoading(false);
        })
    }
    const onCreateUserHandler = (user) => {
        setShowCreateUserPanel(false);
        toast.success(`Usuário ${user.email} criado com sucesso!`);
        listUsers(userList.current_page, searchFilter)
    }
    return (
        <div id="users-page">
            {/*Website navigation Bar  */}
            <ORBNavBar page_title="Admin > Users" selectedOption="drop_admin" />
            
            {/* Page action bar */}
            <div className="page-actionbar-container">
                <div className="page-actionbar-item action-btn" onClick={()=>{setShowFilters(!showFilters)}}>
                    <FilterIcon />
                </div>
                <div className="page-actionbar-item action-btn" onClick={()=>{setShowCreateUserPanel(!showCreateUserPanel);}}>
                    <PlusIcon />
                </div>
            </div>
            {showFilters ? 
            <div className='page-filter-bar'>
                <DebouncedTextField label="Buscar por ..." onChange={((filter) => { listUsers(userList.current_page, filter)})} />
            </div>
            : null
            }

            {isLoading ?
            <Loading text="Carregando usuários" /> :
            <UsersTable data={userList.users} />
            }
            <Pagination className='table-pagination' shape='rounded'
                count={userList.pages} page={userList.current_page} onChange={(e, value) => { listUsers(value, searchFilter);}} />
            {/* Modal panel for user creating */}
            <Modal open={showCreateUserPanel} onClose={()=> {setShowCreateUserPanel(false);}}
                    BackdropComponent={Backdrop} BackdropProps={{timeout: 500}}>
                <div className="modal-container" style={{overflow: 'auto'}}>
                    <NewUserPanel onCreate={onCreateUserHandler}/>
                </div>

            </Modal>

        </div>
    )
}

export default Users;