export default class Translator {
  constructor() {
    this.pages = {
      menu: {
        PT: {
          register: 'Cadastro',
          register_items: [
            'Obras',
            'Fonogramas',
            'Selos',
            'Editoras',
            'Titulares',
            'Clientes',
            'Agentes',
          ],
          files: 'Arquivos',
          files_items: ['Autoral', 'Conexo'],
          distributions: 'Distribuições',
          reports: 'Relatórios',
          dashboards: 'Dashboards',
          dashboards_items: ['Autoral', 'Conexo'],
          session: 'Sessão',
          session_profile: 'Perfil',
          admin: 'Admin',
          admin_items: ['Usuários', 'Levantamentos'],
          session_logout: 'Sair',
        },
        EN: {
          register: 'Register',
          register_items: [
            'Works',
            'Tracks',
            'Labels',
            'Publishers',
            'Holders',
            'Clients',
            'Agents',
          ],
          files: 'Files',
          files_items: ['Copyright', 'Neighboring Rights'],
          distributions: 'Distributions',
          reports: 'Reports',
          dashboards: 'Dashboards',
          dashboards_items: ['Copyright', 'Neighboring Rights'],
          session: 'Session',
          session_profile: 'Profile',
          admin: 'Admin',
          admin_items: ['Users', 'Reports'],
          session_logout: 'Logout',
        },
      },
      tracks: {
        PT: {
          loading: 'Carregando Faixas',
          title_tracks: 'Cadastro > Fonogramas',
          total_tracks: 'Total de faixas',
          headers_table: ['Selo', 'Faixa', 'Artista', 'ISRC'],
          split_headers: ['Categoria', 'Nome', 'Nome artístico', '%','Controlado pela ORB',],
          input_filters: {
            label: 'Selo',
            generic: 'Artista, faixa ou ISRC',
          },
          form_texts: {
            title: 'Criar Faixa',
            title_edit: 'Editar Faixa',
            label: 'Selo',
            track: 'Faixa',
            isrc: 'ISRC',
            artist: 'Artista',
            split_percent: 'Parte em %',
            split_category: 'Categoria',
            split_controlled: 'Controlado pela ORB',
            split_category_items: [
              { value: 'I', name: 'Intérprete' },
              { value: 'PF', name: 'Produtor Fonográfico' },
              { value: 'MA', name: 'Músico Acompanhante' },
              { value: 'CV', name: 'Coro/Voz' },
              { value: 'EMP', name: 'Empresário' },
            ],
            split_button: 'Adicionar',
            split_headers: ['Nome', '%', 'Categoria', 'Controlado pela ORB', 'Opção'],
            save_button: 'SALVAR',
            edit_button: 'EDITAR',
          },
          messages: {
            success_upload: 'Upload Finalizado !',
            sheet_not_found:
              "A planilha precisa ter uma aba com o nome 'Splits'.",
            label_not_found: 'Não foi encontrada',
            contributor_not_found: 'Titulares não encontrados',
            invalid_split: 'A faixa possui um documento inválido',
            invalid_configuration: 'A faixa possui uma configuração inválida',
            required_inputs: 'Por favor, preencha todos os dados !',
            required_split_inputs:
              "Os campos 'nome', 'categoria' e 'parte em %' são obrigatórios no split.",
            verify_total_splits:
              'A soma das pocentagens deve ser de 99% ou 100%',
            unmanaged: 'Nenhum split é gerenciado pela ORB',
            saved_track: 'Faixa Salva',
            saved_edit: 'Edição Finalizada !',
            failed_to_edit: 'Falha ao editar faixa.',
            failed_to_save: 'Falha ao salvar faixa',
            downloaded_file: 'Baixado com sucesso',
            error: 'Erro',
            default: 'Erro inesperado',
          },
        },
        EN: {
          loading: 'Loading Tracks',
          title_tracks: 'Register > Tracks',
          total_tracks: 'Total Tracks',
          headers_table: ['Label', 'Track', 'Artist', 'ISRC'],
          split_headers: ['Category', 'Name', 'Artist Name', '%'],
          input_filters: {
            label: 'Label',
            generic: 'Artist, track or ISRC',
          },
          form_texts: {
            title: 'Create Track',
            title_edit: 'Edit Track',
            label: 'Label',
            track: 'Track',
            isrc: 'ISRC',
            artist: 'Artist',
            split_percent: 'Share %',
            split_category: 'Category',
            split_controlled: 'Controlled by ORB',
            split_category_items: [
              { value: 'I', name: 'Artist' },
              { value: 'PF', name: 'Phonographic Producer' },
              { value: 'MA', name: 'Accompanying Musician' },
              { value: 'CV', name: 'Choir/Voice' },
              { value: 'EMP', name: 'Manager' },
            ],
            split_button: 'Add',
            split_headers: ['Name', '%', 'Category', 'Controlled by ORB', 'Option'],
            save_button: 'SAVE',
            edit_button: 'EDIT',
          },
          messages: {
            success_upload: 'Upload completed!',
            sheet_not_found:
              "The spreadsheet must have a sheet named 'Splits'.",
            label_not_found: 'Label not found.',
            contributor_not_found: 'Contributors not found.',
            invalid_split: 'The track has an invalid document.',
            invalid_configuration: 'The track has an invalid configuration.',
            required_inputs: 'Please fill in all required fields',
            required_split_inputs:
              "The 'name', 'category', and 'percentage' fields are required in the split.",
            verify_total_splits: 'The sum of percentages must be 99% or 100%.',
            unmanaged: 'No splits are managed by ORB.',
            saved_track: 'Track saved.',
            saved_edit: 'Editing completed!',
            failed_to_edit: 'Failed to edit track.',
            failed_to_save: 'Failed to save track.',
            downloaded_file: 'Downloaded successfully.',
            error: 'Error.',
            default: 'Unexpected error.',
          },
        },
      },
      works: {
        PT: {
          loading: 'Carregando Obras',
          title_works: 'Cadastro > Obras',
          total_works: 'Total de obras',
          headers_table: ['ORB code', 'Obra', '% de controle ORB'],
          split_headers: [
            'Autor',
            '% de distribuição',
            '% da ORB',
            'Editora',
            ' % de distribuição',
            '% da ORB',
            'Controlado pela ORB',
          ],
          input_publisher: 'Editora',
          input_filters: {
            publisher: 'Editora',
            generic: 'Obra, ISWC ou ORB code',
          },
          form_texts: {
            title: 'Criar Obra',
            title_edit: 'Editar Obra',
            publisher: 'Editora',
            work: 'Obra',
            iswc: 'ISWC',
            orb_code: 'ORB code',

            split_percent: 'Parte em %',
            split_orb_fee: 'Taxa ORB(%)',
            split_publisher: 'Editora',
            split_controlled: 'Controlado pela ORB',
            split_button: 'Adicionar',
            split_headers: [
              'Autor',
              '% de distribuição',
              'Taxa ORB(%)',
              'Editora',
              '% de distribuição',
              'Taxa ORB(%)',
              'Controlado pela ORB',
              'Opção',
            ],
            save_button: 'SALVAR',
            edit_button: 'EDITAR',
          },
          dialog_window: {
            message:
              'Alguns titulares nao foram cadastrados, gostaria de cadastra-los ?',
            confirm_button: 'Sim',
            cancel_button: 'Cancelar',
          },
          register_contributors: {
            title: 'Titulares nao cadastrados',
            header_table: ['Nome', 'Documento', 'Nome Artistico'],
          },
          messages: {
            success_upload: 'Upload Finalizado !',
            success_download: 'Baixado com sucesso !',
            not_found: 'Obra não foi encontrada.',
            invalid_configuration: 'A Obra possui uma configuração inválida',
            invalid_parameters: 'Erro nos parametros informados',
            default: 'Erro inesperado',
            required_holder: "O campo 'titular' é obrigatório no split.",
            required_share: "O campo 'parte em %' é obrigatório no split.",
            verify_total_splits:
              'A soma das pocentagens deve ser de 99% ou 100%',
            unmanaged: 'Nenhum split é gerenciado pela ORB',
            required_inputs: 'Por favor, preencha todos os dados',
            finished_edition: 'Edição Finalizada !',
            editing_failed: 'Falha ao editar Obra.',
            saved_work: 'Obra Salva',
            failed_to_save: 'Falha ao salvar Obra',
            downloaded_file: 'Baixado com sucesso',
            error: 'Erro',
          },
        },
        EN: {
          loading: 'Loading Works',
          title_works: 'Register > Works',
          total_works: 'Total Works',
          headers_table: ['ORB code', 'Work', 'ORB Control %'],
          split_headers: [
            'Author',
            'Distribution %',
            'ORB %',
            'Publisher',
            'Distribution %',
            'ORB %',
            'Controlled by ORB',
          ],
          input_publisher: 'Publisher',
          input_filters: {
            publisher: 'Publisher',
            generic: 'Work, ISWC or ORB code',
          },
          form_texts: {
            title: 'Create Work',
            title_edit: 'Edit Work',
            publisher: 'Publisher',
            work: 'Work',
            iswc: 'ISWC',
            orb_code: 'ORB code',
            split_percent: 'Share',
            split_orb_fee: 'ORB Fee %',
            split_publisher: 'Publisher',
            split_controlled: 'Controlled by ORB',
            split_button: 'Add',
            split_headers: [
              'Author',
              'Distribution %',
              'ORB Fee %',
              'Publisher',
              'Distribution %',
              'ORB Fee %',
              'Controlled by ORB',
              'Option',
            ],
            save_button: 'SAVE',
            edit_button: 'EDIT',
          },
          dialog_window: {
            message:
              'Some holders have not been registered. Would you like to register them?',
            confirm_button: 'Yes',
            cancel_button: 'Cancel',
          },
          register_contributors: {
            title: 'Unregistered Holders',
            header_table: ['Name', 'Document', 'Artist Name'],
          },
          messages: {
            success_upload: 'Upload completed!',
            success_download: 'Download successful!',
            not_found: 'Work not found.',
            invalid_configuration: 'The work has an invalid configuration.',
            invalid_parameters: 'Error in the provided parameters.',
            default: 'Unexpected error.',
            required_holder: "The 'holder' field is required in the split.",
            required_share:
              "The 'share percentage' field is required in the split.",
            verify_total_splits: 'The sum of percentages must be 99% or 100%.',
            unmanaged: 'No split is managed by ORB.',
            required_inputs: 'Please fill in all the data.',
            finished_edition: 'Edition completed!',
            editing_failed: 'Failed to edit work.',
            saved_work: 'Work saved.',
            failed_to_save: 'Failed to save work.',
            downloaded_file: 'Downloaded Successfully',
            error: 'Error',
          },
        },
      },
      holders: {
        PT: {
          loading: 'Carregando Titulares',
          title_holders: 'Cadastro > Titulares',
          total_holders: 'Total de titulares',
          headers_table: ['Nome', 'Nome Artistico', 'Documento'],
          input_filter: 'Nome ou Documento',
          form_texts: {
            title: 'Criar Titular',
            title_edit: 'Editar Titular',
            input_name: 'Nome',
            input_artist_name: 'Nome Artistico',
            input_doc: 'Documento',
            save_button: 'SALVAR',
            edit_button: 'EDITAR',
          },
          messages: {
            invalid_document: 'CPF/CNPJ inválido',
            failed_to_save: 'Falha ao salvar dados do titular',
            failed_to_edit: 'Falha ao editar dados do titular',
            saved_holder: 'Titular salvo com sucesso !',
            required_inputs: 'Por favor preencha o nome do titular',
          },
        },
        EN: {
          loading: 'Loading Holders',
          title_holders: 'Register > Holders',
          total_holders: 'Total holders',
          headers_table: ['Name', 'Artist Name', 'Document'],
          input_filter: 'Name or Document',
          form_texts: {
            title: 'Create Holder',
            title_edit: 'Edit Holder',
            input_name: 'Name',
            input_artist_name: 'Artist Name',
            input_doc: 'Document',
            save_button: 'SAVE',
            edit_button: 'EDIT',
          },
          messages: {
            invalid_document: 'Invalid CPF/CNPJ.',
            failed_to_save: 'Failed to save holder data.',
            failed_to_edit: 'Failed to edit holder data.',
            saved_holder: 'Holder saved successfully!',
            required_inputs: 'Please fill in the holder name.',
          },
        },
      },
      distributions: {
        PT: {
          title: 'Distribuições',
          headers_table: [
            'Data de referência',
            'Nome',
            'Valor distribuido',
            'Status',
            'Opções',
            'Download',
          ],
          form_title: 'Nova Distribuição',
          form_title_edit: 'Editar Distribuição',
          form_input_names: {
            reference_date: 'Dt de referência',
            distribution_name: 'Distribuição',
            currency: 'Moeda',
          },
          form_table_title: 'Arquivos a distribuir',
          form_header: ['Data', 'Origem', 'Tipo'],
          save_button: 'SALVAR',
          edit_button: 'EDITAR',
          approve_distribution: {
            confirm_message:
              'Tem certeza que deseja aprovar a distribuição abaixo?',
            refuse_message:
              'Tem certeza de que deseja recusar a seguinte distribuição?',
            cancel: 'Cancelar',
            confirm: 'Confirmar',
          },
          messages: {
            failed_to_download: 'Falha ao baixar atualização',
            distribution_sent: 'Distribuição enviada para o seu email',
            distribution_send_error: 'Falha ao enviar distribuição',
            approved_distribution: 'Distribuição aprovada',
            failed_approval_message: 'Falha ao aprovar a distribuição',
            rejected_distribution: 'Distribuição reprovada',
            rejection_failed: 'Falha ao reprovar a distribuição',
            created: 'Distribuição criada',
            create_failed: 'Falha ao criar distribuição',
            updated: 'Distribuição atualizada',
            update_failed: 'Falha ao atualizar distribuição',
            payment_files_load_failed:
              'Falha ao carregar arquivos de pagamento',
          },
        },
        EN: {
          title: 'Distributions',
          headers_table: [
            'Reference Date',
            'Name',
            'Distributed Value',
            'Status',
            'Options',
            'Download',
          ],
          form_title: 'New Distribution',
          form_title_edit: 'Edit Distribution',
          form_input_names: {
            reference_date: 'Reference Date',
            distribution_name: 'Distribution Name',
            currency: 'Currency',
          },
          form_table_title: 'Files to Distribute',
          form_header: ['Date', 'Origin', 'Type'],
          save_button: 'SAVE',
          edit_button: 'EDIT',
          approve_distribution: {
            confirm_message:
              'Are you sure you want to approve the following distribution?',
            refuse_message:
              'Are you sure you want to refuse the following distribution?',
            cancel: 'Cancel',
            confirm: 'Confirm',
          },
          messages: {
            failed_to_download: 'Failed to download update',
            distribution_sent: 'Distribution sent to your email',
            distribution_send_error: 'Failed to send distribution',
            approved_distribution: 'Distribution approved',
            failed_approval_message: 'Failed to approve distribution',
            rejected_distribution: 'Distribution rejected',
            rejection_failed: 'Failed to reject distribution',
            created: 'Distribution created',
            create_failed: 'Failed to create distribution',
            updated: 'Distribution updated',
            update_failed: 'Failed to update distribution',
            payment_files_load_failed: 'Failed to load payment files',
          },
        },
      },
      reports: {
        PT: {
          title: 'Relatórios',
          clients_table: [
            'Data de Referência',
            'Clientes',
            'Montante',
            'Download',
          ],
          contributors_table: [
            'Data de referência',
            'Titular',
            'Montante',
            'Cliente',
            'Download',
          ],
          input_filters: {
            clients: 'Clientes',
            month: 'Mês',
            year: 'Ano',
            holder: 'titular',
          },
          messages: {
            report_send:
              'Pronto! Você receberá o relatório no seu email em alguns minutos',
            report_send_error:
              'Houve um erro ao tentar enviar o relatório para o seu email.',
            success_download: 'Baixado com sucesso',
          },
        },
        EN: {
          title: 'Reports',
          clients_table: ['Reference Date', 'Clients', 'Amount', 'Download'],
          contributors_table: [
            'Reference Date',
            'Holder',
            'Amount',
            'Client',
            'Download',
          ],
          input_filters: {
            clients: 'Clients',
            month: 'Month',
            year: 'Year',
            holder: 'Holder',
          },
          messages: {
            report_send:
              'Done! You will receive the report in your email in a few minutes',
            report_send_error:
              'There was an error while trying to send the report to your email.',
            success_download: 'Successfully downloaded',
          },
        },
      },
      reports_graph: {
        PT: {
          header: {
            title: 'ROYALTIES REPORT',
            period: 'Período',
            title_autoral: 'Digital Autoral',
            title_conexo: 'Digital Conexo',
            client: 'Cliente',
            holder: 'Títular',
            artist_name: 'Nome Artistico',
            doc: 'Doc',
            cambio_table_headers: ['', 'Moeda', 'Valor'],
          },
          title: 'DISTRIBUIÇÃO POR TIPO DE RECEITA',
          top_works: 'TOP OBRAS',
          top_works_headers: ['TÍTULO', 'STREAMS', 'VALOR LÍQUIDO DO TÍTULAR'],
          top_tracks_headers: ['TÍTULO', 'STREAMS', 'VALOR LÍQUIDO DO TÍTULAR'],
          top_tracks: 'TOP FONOGRAMAS',
          platforms: 'PLATAFORMAS',
          selling_months: 'PERÍODO DE VENDAS',
          headers_table_autoral: [
            'MÊS DE VENDA',
            'TÍTULO',
            'INTÉRPRETE',
            'PLATAFORMA',
            'STREAMS',
            'RECEITA TOTAL',
            'PARTICIPAÇÃO',
            'RECEITA BRUTA TITULAR',
            'TAXA SERVIÇO',
            'RECEITA LÍQUIDA TÍTULAR',
          ],
          headers_table_conexo: [
            'MÊS DE VENDA',
            'TÍTULO',
            'INTÉRPRETE',
            'PLATAFORMA',
            'STREAMS',
            'RECEITA TOTAL',
            'RECEITA LÍQUIDA',
            'CATEGORIA',
            '%',
            'RECEITA TITULAR',
          ],
          tab_buttons: {
            autoral: 'Autoral',
            conexo: 'Conexo',
          },
        },
        EN: {
          header: {
            title: 'ROYALTIES REPORT',
            period: 'Period',
            title_autoral: 'Digital copyright',
            title_conexo: 'Digital neighboring rights',
            client: 'Client',
            holder: 'Holder',
            artist_name: 'Artist Name',
            doc: 'Doc',
            cambio_table_headers: ['Aggregator', 'Currency', 'Value'],
          },
          top_works: 'TOP WORKS',
          top_works_headers: ['TITLE', 'STREAMS', 'NET HOLDER VALUE'],
          top_tracks: 'TOP TRACKS',
          top_tracks_headers: ['TITLE', 'STREAMS', 'NET HOLDER VALUE'],
          platforms: 'PLATFORMS',
          selling_months: 'SELLING MONTHS',
          headers_table_autoral: [
            'SALES MONTH',
            'TITLE',
            'TOTAL REVENUE',
            'NET REVENUE',
            'PLATFORM',
            'STREAMS',
            'ARTIST',
            '%',
            'HOLDER REVENUE',
          ],
          headers_table_conexo: [
            'SALES MONTH',
            'TITLE',
            'ARTIST',
            'PLATFORM',
            'STREAMS',
            'TOTAL REVENUE',
            'NET REVENUE',
            'CATEGORY',
            '%',
            'HOLDER REVENUE',
          ],
          tab_buttons: {
            autoral: 'Copyright',
            conexo: 'Neighboring Rights',
          },
        },
      },
      dashboards: {
        PT: {
          loading: 'Carregando dashboard',
          title_conexo: 'Dashboard > conexo',
          title_autoral: 'Dashboard > autoral',
          inputs: {
            distribution_period: 'Período de distribuição',
            sales_period: 'Período de venda',
            start_date: 'Data de início',
            end_date: 'Data de término',
            label: 'Selo',
            publisher: 'Editora',
            button: 'Filtrar',
          },
          monthly_distribution: 'Distribuição por mês',
          dsp_distribution: 'Distribuição por DSP',
          track_distribution: 'Distribuição por faixa',
          work_distribution: 'Distribuição por obra',
          territory_distribution: 'Distribuição por território',
          holder_distribution: 'Distribuição por titular',
        },
        EN: {
          loading: 'Loading dashboard',
          title_conexo: 'Dashboard > Neighboring Rights',
          title_autoral: 'Dashboard > Copyright',
          inputs: {
            distribution_period: 'Distribution Period',
            sales_period: 'Sales Period',
            start_date: 'Start Date',
            end_date: 'End Date',
            label: 'Label',
            publisher: 'Publisher',
            button: 'Filter',
          },
          monthly_distribution: 'Monthly Distribution',
          dsp_distribution: 'DSP Distribution',
          track_distribution: 'Track Distribution',
          work_distribution: 'Work Distribution',
          territory_distribution: 'Territory Distribution',
          holder_distribution: 'Holder Distribution',
        },
      },
    };
  }

  translate(page, language_preference) {
    if (!this.pages.hasOwnProperty(page)) {
      return null;
    }

    if (!this.pages[page].hasOwnProperty(language_preference)) {
      return null;
    }

    return this.pages[page][language_preference];
  }
}
