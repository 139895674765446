import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';

const UsersTable = ({data}) => {

    return (
        <>
        <TableContainer className='table-container'>
            <Table size="small" className="basic-table">
            <TableHead className="basic-table-head">
                <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Documento</TableCell>
                <TableCell>Perfil</TableCell>
                <TableCell>Representa</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {data ? data.map((user, index) => {
                return (
                    <TableRow className='row' key={user.email}>
                        <TableCell>{user.status}</TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.document}</TableCell>
                        <TableCell>{user.type}</TableCell>
                        <TableCell>{user.contributor ? user.contributor : user.client ? user.client : null}</TableCell>
                    </TableRow>
                )
            }) : null}
            </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}

export default UsersTable;