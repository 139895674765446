import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  BrowserRouter,
  Redirect,
  Switch,
} from 'react-router-dom';
import Login from '../pages/Login';
import Works from '../pages/Register/Works';
import Clients from '../pages/Register/Clients';
import Contributors from '../pages/Register/Contributors';

import Agents from '../pages/Register/Agents';
import Phonograms from '../pages/Register/Phonograms';
import Labels from '../pages/Register/Labels';
import Publishers from '../pages/Register/Publishers';
import Conexo from '../pages/Files/Conexo';
import Autoral from '../pages/Files/Autoral';
import ReportGraph from '../pages/ReportGraph';
import Reports from '../pages/Reports';
import TracksDashboard from '../pages/Dashboard/Tracks';
import WorksDashboard from '../pages/Dashboard/Works';
import Users from '../pages/Admin/Users';
import AdminReports from '../pages/Admin/Reports';

import Distributions from '../pages/Distributions';
import SessionContext, { SessionProvider } from '../contexts/SessionContext';

//Users access /Main if it provides a token, otherwise will be redirected for the login page.

const PrivateRoute = ({ component: Component, ...rest }) => {
  let sessionContext = React.useContext(SessionContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        sessionContext.session_data ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};
const Navigation = () => (
  <SessionProvider>
    <BrowserRouter>
      <Switch>
        <Router>
          <Route exact path="/" component={Login} />
          <PrivateRoute path="/register/works" component={Works} />
          <PrivateRoute path="/register/holders" component={Contributors} />
          <PrivateRoute path="/register/tracks" component={Phonograms} />
          <PrivateRoute path="/register/labels" component={Labels} />
          <PrivateRoute path="/register/publishers" component={Publishers} />
          <PrivateRoute path="/register/clients" component={Clients} />
          <PrivateRoute path="/register/agents" component={Agents} />
          <PrivateRoute path="/files/Neighboring_Rights" component={Conexo} />
          <PrivateRoute path="/files/copyright" component={Autoral} />
          <PrivateRoute path="/distributions" component={Distributions} />
          <PrivateRoute path="/reports" component={Reports} />
          <PrivateRoute path="/graphs" component={ReportGraph} />
          <PrivateRoute path="/admin/users" component={Users} />
          <PrivateRoute path="/admin/reports" component={AdminReports} />
          <PrivateRoute
            path="/dashboard/neighboring_rights"
            component={TracksDashboard}
          />
          <PrivateRoute
            path="/dashboard/copyright"
            component={WorksDashboard}
          />
        </Router>
      </Switch>
    </BrowserRouter>
  </SessionProvider>
);

export default Navigation;
