import React, { useState, useEffect, useContext } from 'react';
import { Grid, TextField, Modal, Backdrop } from '@material-ui/core';
import Loading from '../Loading';
import { useDebouncedCallback } from 'use-debounce/lib';
import AddIcon from '@material-ui/icons/Add';
import ORBApi from '../../../services/ORBApi';
import './style.css';
import ContributorsForm from '../../../pages/Register/Contributors/components/ContributorsForm';
import { getTranslatedTexts } from '../../../utils/TranslationUtils';
import SessionContext from '../../../contexts/SessionContext';

const SuggestContributors = ({onChange,  useToFilter, clearInput, value, margin, error, helperText, required}) => {

  const [contributors, setContributors] = useState([]);
  const [contributorName, setContributorName] = useState('');
  const [filters, setFilters] = useState({ term: '' });
  const [loading, setLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const debounced = useDebouncedCallback((value) => {
    setFilters({ ...filters, term: value.toUpperCase() });
  }, 500);
  let {
    session_data: { language_preference },
  } = useContext(SessionContext);
  const [texts, setTexts] = useState(null);

  const loadData = (filters) => {
    setLoading(true);
    ORBApi.loadContributors(1, filters, 0)
      .then((data) => {
        setContributors(data.contributors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickContributor = (contributor) => {
    //clean filtes
    setFilters({ ...filters, term: '' });

    // set new value to inputText  (name + artist-name)
    setContributorName(getFormattedName(contributor));

    let newValue = { id: contributor.id, name: contributor.name };
    onChange(newValue);
  };

  const getFormattedName = (contributor) => {
    return contributor.name && contributor.artist_name
      ? `${contributor.name} (${contributor.artist_name})`
      : contributor.name;
  };
  const onSaveContributor = (created_contributor) => {
    setFormVisible(false);
    onClickContributor(created_contributor);
  };

  useEffect(() => {
    if (filters.term) {
      loadData(filters);
    }
    if (filters.term === '') {
      setContributors(null);
    }
  }, [filters]);

  useEffect(() => {
    if (clearInput) setContributorName('');
    if (value) setContributorName(value);
    else setContributorName('');
  }, [clearInput, value]);

  useEffect(() => {
    const translatedTexts = getTranslatedTexts('holders', language_preference);
    setTexts(translatedTexts);
  }, [language_preference]);

  return (
    <Grid container sx={12}>
      <div style={{ position: 'relative', width:'100%' }} >
      <TextField
        fullWidth
        label={language_preference === 'EN' ? 'Holder' : 'Titular'}
        variant="outlined"
        value={contributorName}
        required={required}
        onChange={(e) => {
          debounced.callback(e.target.value);
          setLoading(true);
          setContributorName(e.target.value.toUpperCase());
          // when the input value is empty, call the "onChange" function to update the filters
          if (!e.target.value) onChange({ id: '', name: e.target.value });
        }}
        error={contributorName? false : error}
        helperText={contributorName ? false : helperText}
        margin={margin ? "normal" : "none" }
      />
      {filters.term ? (
        <Grid className="contributors-container">
          {contributors?.length > 0 ? (
            <ul className="contributors-list">
              {contributors.map((contributor) => (
                <li
                  key={contributor.id}
                  onClick={() => {
                    onClickContributor(contributor);
                  }}
                >
                  {getFormattedName(contributor)}
                </li>
              ))}
            </ul>
          ) : loading ? (
            <ul className="contributors-list">
              <li>
                <Loading className="loading-box" size="20px" />
              </li>
            </ul>
          ) : null}
          {useToFilter ? null : (
            <div className="contributors-list">
              <div
                className="add-contributor-button clickable"
                onClick={(e) => {
                  setFormVisible(true);
                }}
              >
                <AddIcon fontSize="small" color="inherit" />
                <span>
                  {language_preference === 'EN'
                    ? 'Register new holder'
                    : 'Cadastrar novo títular'}
                </span>
              </div>
            </div>
          )}
        </Grid>
      ) : null}
      <Modal
        size="lg"
        open={formVisible}
        onClose={() => setFormVisible(!formVisible)}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className="modal-container">
          <ContributorsForm
            messages={texts ? texts.messages : {}}
            form_texts={texts ? texts.form_texts : {}}
            onSaveContributor={onSaveContributor}
          />
        </div>
      </Modal>
      </div>
      
    </Grid>
  );
};

export default SuggestContributors;
