import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import SessionContext, { SystemRoles } from '../../contexts/SessionContext';

export default function DropDownMenu({
  className,
  path,
  title,
  list,
  pageAccess,
  roles,
}) {
  let sessionContext = React.useContext(SessionContext);
  const shouldShow = (itemRoles) => {
    return sessionContext.hasRole(itemRoles);
  };

  function selectedPath(item_list) {
    switch (item_list) {
      case 'Copyright':
        return 'Copyright';
      case 'Neighboring Rights':
        return 'Neighboring_Rights';
      case 'Autoral':
        return 'Copyright';
      case 'Conexo':
        return 'Neighboring_Rights';
      case 'Fonogramas':
        return 'Tracks';
      case 'Obras':
        return 'Works';
      case 'Selos':
        return 'Labels';
      case 'Titulares':
        return 'Holders';
      case 'Editoras':
        return 'Publishers';
      case 'Clientes':
        return 'Clients';
      case 'Agentes':
        return 'Agents';
      case 'Usuários':
        return 'Users'
      case 'Levantamentos':
        return 'Reports'
      default:
        return item_list;
    }
  }

  return (
    <NavDropdown
      title={title}
      style={{ padding: '0em' }}
      className={className}
      id={`basic-nav-dropdown-${title}`}
    >
      {list.map((itemList, key) => {
        return (
          <div key={key}>
            {shouldShow(roles[key]) ? (
              <>
                <NavDropdown.Item
                  onClick={(e) =>
                    pageAccess(e, `/${path}/${selectedPath(itemList)}`)
                  }
                >
                  {itemList}
                </NavDropdown.Item>

                <NavDropdown.Divider />
              </>
            ) : null}
          </div>
        );
      })}
    </NavDropdown>
  );
}
